import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "LolAnalystApp | Privacy Policy";
  }, []);
  return (
    <div className="privacy-policy">
      <h1 className="heading-large">Privacy Policy</h1>
      <p className="paragraph">Last updated: March 10, 2022</p>
      <p className="paragraph">
        At LolAnalystApp, accessible from{" "}
        <a href="https://lolanalyst.app" target="_blank" rel="noreferrer">
          https://lolanalyst.app
        </a>
        , one of our main priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by LolAnalystApp and how we
        use it.
      </p>

      <p className="paragraph">
        If you have additional questions or require more information about our Privacy Policy, do
        not hesitate to contact us.
      </p>

      <p className="paragraph">
        This Privacy Policy applies only to our online activities and is valid for visitors to our
        website with regards to the information that they shared and/or collect in LolAnalystApp.
        This policy is not applicable to any information collected offline or via channels other
        than this website.
      </p>

      <h1 className="heading-large">Consent</h1>

      <p className="paragraph">
        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
      </p>

      <h1 className="heading-large">Information we collect</h1>

      <p className="paragraph">
        The personal information that you are asked to provide, and the reasons why you are asked
        to provide it, will be made clear to you at the point we ask you to provide your personal
        information.
      </p>
      <p className="paragraph">
        If you contact us directly, we may receive additional information about you such as your
        name, email address, phone number, the contents of the message and/or attachments you may
        send us, and any other information you may choose to provide.
      </p>
      <p className="paragraph">When you register for an Account, we may ask for your email.</p>

      <h1 className="heading-large">How we use your information</h1>

      <p className="paragraph">We use the information we collect in various ways, including to:</p>

      <ul className="paragraph">
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners, including for
          customer service, to provide you with updates and other information relating to the
          website, and for marketing and promotional purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h1 className="heading-large">Log Files</h1>

      <p className="paragraph">
        LolAnalystApp follows a standard procedure of using log files. These files log visitors
        when they visit websites. All hosting companies do this and a part of hosting services'
        analytics. The information collected by log files include internet protocol (IP) addresses,
        browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages,
        and possibly the number of clicks. These are not linked to any information that is
        personally identifiable. The purpose of the information is for analyzing trends,
        administering the site, tracking users' movement on the website, and gathering demographic
        information.
      </p>

      <h1 className="heading-large">Cookies and Web Beacons</h1>

      <p className="paragraph">
        Like any other website, LolAnalystApp uses 'cookies'. These cookies are used to store
        information including visitors' preferences, and the pages on the website that the visitor
        accessed or visited. The information is used to optimize the users' experience by
        customizing our web page content based on visitors' browser type and/or other information.
      </p>

      <h1 className="heading-large">Google DoubleClick DART Cookie</h1>

      <p className="paragraph">
        Google is one of a third-party vendor on our site. It also uses cookies, known as DART
        cookies, to serve ads to our site visitors based upon their visit to www.website.com and
        other sites on the internet. However, visitors may choose to decline the use of DART
        cookies by visiting the Google ad and content network Privacy Policy at the following URL –{" "}
        <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noreferrer">
          https://policies.google.com/technologies/ads
        </a>
      </p>

      <h1 className="heading-large">Our Advertising Partners</h1>

      <p className="paragraph">
        Some of advertisers on our site may use cookies and web beacons. Our advertising partners
        are listed below. Each of our advertising partners has their own Privacy Policy for their
        policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
      </p>

      <ul className="paragraph">
        <li>
          <p className="paragraph">Google</p>
          <p className="paragraph">
            <a
              href="https://policies.google.com/technologies/ads"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/technologies/ads
            </a>
          </p>
        </li>
      </ul>

      <h1 className="heading-large">Advertising Partners Privacy Policies</h1>

      <p className="paragraph">
        You may consult this list to find the Privacy Policy for each of the advertising partners
        of LolAnalystApp.
      </p>

      <p className="paragraph">
        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
        Beacons that are used in their respective advertisements and links that appear on
        LolAnalystApp, which are sent directly to users' browser. They automatically receive your
        IP address when this occurs. These technologies are used to measure the effectiveness of
        their advertising campaigns and/or to personalize the advertising content that you see on
        websites that you visit.
      </p>

      <p className="paragraph">
        Note that LolAnalystApp has no access to or control over these cookies that are used by
        third-party advertisers.
      </p>

      <h1 className="heading-large">Third Party Privacy Policies</h1>

      <p className="paragraph">
        LolAnalystApp's Privacy Policy does not apply to other advertisers or websites. Thus, we
        are advising you to consult the respective Privacy Policies of these third-party ad servers
        for more detailed information. It may include their practices and instructions about how to
        opt-out of certain options.{" "}
      </p>

      <p className="paragraph">
        You can choose to disable cookies through your individual browser options. To know more
        detailed information about cookie management with specific web browsers, it can be found at
        the browsers' respective websites.
      </p>

      <h1 className="heading-large">CCPA Privacy Rights (Do Not Sell My Personal Information)</h1>

      <p className="paragraph">
        Under the CCPA, among other rights, California consumers have the right to:
      </p>
      <p className="paragraph">
        Request that a business that collects a consumer's personal data disclose the categories
        and specific pieces of personal data that a business has collected about consumers.
      </p>
      <p className="paragraph">
        Request that a business delete any personal data about the consumer that a business has
        collected.
      </p>
      <p className="paragraph">
        Request that a business that sells a consumer's personal data, not sell the consumer's
        personal data.
      </p>
      <p className="paragraph">
        If you make a request, we have one month to respond to you. If you would like to exercise
        any of these rights, please contact us.
      </p>

      <h1 className="heading-large">GDPR Data Protection Rights</h1>

      <p className="paragraph">
        We would like to make sure you are fully aware of all of your data protection rights. Every
        user is entitled to the following:
      </p>
      <p className="paragraph">
        The right to access – You have the right to request copies of your personal data. We may
        charge you a small fee for this service.
      </p>
      <p className="paragraph">
        The right to rectification – You have the right to request that we correct any information
        you believe is inaccurate. You also have the right to request that we complete the
        information you believe is incomplete.
      </p>
      <p className="paragraph">
        The right to erasure – You have the right to request that we erase your personal data,
        under certain conditions.
      </p>
      <p className="paragraph">
        The right to restrict processing – You have the right to request that we restrict the
        processing of your personal data, under certain conditions.
      </p>
      <p className="paragraph">
        The right to object to processing – You have the right to object to our processing of your
        personal data, under certain conditions.
      </p>
      <p className="paragraph">
        The right to data portability – You have the right to request that we transfer the data
        that we have collected to another organization, or directly to you, under certain
        conditions.
      </p>
      <p className="paragraph">
        If you make a request, we have one month to respond to you. If you would like to exercise
        any of these rights, please contact us.
      </p>

      <h1 className="heading-large">Children's Information</h1>

      <p className="paragraph">
        Another part of our priority is adding protection for children while using the internet. We
        encourage parents and guardians to observe, participate in, and/or monitor and guide their
        online activity.
      </p>

      <p className="paragraph">
        LolAnalystApp does not knowingly collect any Personal Identifiable Information from
        children under the age of 13. If you think that your child provided this kind of
        information on our website, we strongly encourage you to contact us immediately and we will
        do our best efforts to promptly remove such information from our records.
      </p>

      <h1 className="heading-large">Links to Other Websites</h1>
      <p className="paragraph">
        Our service may contain links to other websites that are not operated by us. If You click
        on a third party link, You will be directed to that third party's site. We strongly advise
        you to review the privacy policy of every site You visit.
      </p>
      <p className="paragraph">
        We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.
      </p>
      <h1 className="heading-large">Changes to this Privacy Policy</h1>
      <p className="paragraph">
        We may update our privacy policy from time to time. You are advised to review this privacy
        policy periodically for any changes. Changes to this privacy policy are effective when they
        are posted on this page.
      </p>
      <h1 className="heading-large">Contact Us</h1>
      <p className="paragraph">
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul className="paragraph">
        <li>
          {"By visiting this page on our website: "}
          <Link to={"/contact-us"}>https://lolanalyst.app/contact-us</Link>
        </li>
      </ul>
    </div>
  );
}
