import React from "react";

export default function RiotLegalBoilerplate() {
  return (
    <div className="legal-boilerplate muted">
      LolAnalystApp isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot
      Games or anyone officially involved in producing or managing Riot Games properties. Riot
      Games, and all associated properties are trademarks or registered trademarks of Riot Games,
      Inc.
    </div>
  );
}
